import React, { createContext, useEffect, useState } from "react";
import { injected, walletlink } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import { ACTIVE_NETWORK, GenerativeNFTContract } from "src/constants";
import {
  swichNetworkHandler,
  getWeb3ContractObject,
  getBalanceOf,
  getWeb3Obj,
  getContract,
} from "src/utils";
import GenerativeNFTABI from "src/ABI/GenerativeNFTABI.json";
import axios from "axios";
import { toast } from "react-toastify";
import { SUPPORTED_WALLETS } from "src/connectors";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};

export default function AuthProvider(props) {
  const { activate, chainId, library, account, deactivate } = useWeb3React();
  const [MAX_NFT_SUPPLY, setMAX_NFT_SUPPLY] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [balanceOfValue, setBalanceOfValue] = useState(0);
  const [adminWalletAddress, setAdminWalletAddress] = useState("");
  const [userNFTList, setUserNFTList] = useState([]);
  const [saleIsActive, setSaleIsActive] = useState();
  const [commonTotalNFT, setcommonTotalNFT] = useState(0);
  const [epicTotalNFT, setepicTotalNFT] = useState(0);
  const [rareTotalNFT, setrareTotalNFT] = useState(0);

  const connectWalletHandler = (data) => {
    try {
      const connector = data.connector;

      if (connector && connector.walletConnectProvider?.wc?.uri) {
        connector.walletConnectProvider = undefined;
      }

      activate(connector, undefined, true).catch((error) => {
        if (error) {
          window.sessionStorage.removeItem("walletName");
          toast.error(JSON.stringify(error.message));
          window.sessionStorage.removeItem("walletName");
          activate(connector);
        }
      });
    } catch (error) {
      toast.error(JSON.stringify(error.message));
    }
  };

  useEffect(() => {
    if (window.sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter(
        (data) => data.name === window.sessionStorage.getItem("walletName")
      );
      connectWalletHandler(selectectWalletDetails[0].data);
    }
  }, []);

  const userNFTListHadler = async (balanceOf, cancelTokenSource) => {
    setUserNFTList([]);
    const contract = getContract(
      GenerativeNFTContract,
      GenerativeNFTABI,
      library,
      account
    );

    try {
      for (let i = 0; i < balanceOf; i++) {
        const id = await contract.tokenOfOwnerByIndex(account, i);
        const filter = await contract.tokenURI(id.toString());

        const res = await axios.get(filter, {
          cancelToken: cancelTokenSource && cancelTokenSource.token,
        });
        if (res.status === 200) {
          setUserNFTList((prev) => [
            ...prev,
            { id: id.toString(), nfdData: res.data },
          ]);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const cancelTokenSource = axios.CancelToken.source();
    if (balanceOfValue > 0) {
      userNFTListHadler(balanceOfValue, cancelTokenSource);
    }
    return () => {
      cancelTokenSource.cancel();
    };
  }, [balanceOfValue, account, chainId]); //eslint-disable-line

  async function getBalanceOfFun() {
    setBalanceOfValue(
      await getBalanceOf(GenerativeNFTABI, GenerativeNFTContract, account)
    );
  }

  useEffect(() => {
    if (account) {
      getBalanceOfFun();
    }
  }, [account, chainId]); //eslint-disable-line

  const getContractDetails = async () => {
    try {
      const web3 = await getWeb3Obj();
      const contractObj = await getWeb3ContractObject(
        GenerativeNFTABI,
        GenerativeNFTContract
      );
      const adminAccount = await contractObj.methods.owner().call();
      setAdminWalletAddress(adminAccount);
      console.log("contractObj", contractObj.methods);

      const commonTotalNFT = await contractObj.methods.commonTotalNFT().call();
      setcommonTotalNFT(commonTotalNFT.toString());
      const epicTotalNFT = await contractObj.methods.epicTotalNFT().call();
      setepicTotalNFT(epicTotalNFT.toString());

      const rareTotalNFT = await contractObj.methods.rareTotalNFT().call();
      setrareTotalNFT(rareTotalNFT.toString());
    } catch (error) {
      console.log("ERROR", error);
    }
  };

  const getCurrentMintingDetails = async () => {
    const contractObj = await getWeb3ContractObject(
      GenerativeNFTABI,
      GenerativeNFTContract
    );
    if (account) {
      getBalanceOfFun();
    }

    const MAX_NFT_SUPPLY = await contractObj.methods.MAX_NFT_SUPPLY().call();
    setMAX_NFT_SUPPLY(Number(MAX_NFT_SUPPLY));
    const totalSupply = await contractObj.methods.totalSupply().call();
    setTotalSupply(Number(totalSupply));
    const saleIsActive = await contractObj.methods.saleIsActive().call();
    setSaleIsActive(saleIsActive);
  };

  useEffect(() => {
    getCurrentMintingDetails();
    getContractDetails();
  }, []); //eslint-disable-line

  useEffect(() => {
    if (chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); //eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  let data = {
    balanceOfValue,
    MAX_NFT_SUPPLY,
    totalSupply,
    adminWalletAddress,
    userNFTList,
    saleIsActive,
    commonTotalNFT,
    epicTotalNFT,
    rareTotalNFT,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectWalletHandler(data),
    disConnectWallet: (data) => {
      deactivate();
      window.sessionStorage.removeItem("walletName");
    },
    getCurrentMintingDetails: () => getCurrentMintingDetails(),
  };

  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}
