import _ from 'lodash'
import { colors, createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import typography from './typography'

const baseOptions = {
  typography,
  overrides: {
    PrivateTabIndicator: {
      colorSecondary: {
        backgroundColor: '#de337a',
      },
    },
    MuiSelect: {
      icon: {
        top: 'calc(50% - 12px)',
        // color: "rgba(0, 0, 0, 0.54)",
        right: '0px',
        position: 'absolute',
        pointerEvents: 'none',
        color: '#fff',
      },
    },
    MuiFormControl: {
      root: {
        border: '0px',
        margin: '0px',
        // display: inline-flex;
        padding: '0px',
        position: 'relative',
        width: '100%',
        flexDirection: 'column',
        verticalAlign: 'top',
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: '600px !important',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0px',
        paddingBottom: '0px',
      },
    },
    MuiTableCell: {
      head: {
        color: '#fff',
        backgroundColor: '#9f7d17',
      },
      body: {
        color: '#fff',
      },
      root: {
        padding: '7px 15px',
        borderBottom: ' transparent',
      },
    },
    MuiInputBase: {
      root: {
        color: '#fff',
      },
      input: {
        padding: ' 12px 10px',
        fontSize: '14px',
        color: '#fff',
        //backgroundColor: '#23413D',
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: 'rgb(235 224 224 / 23%)',
        background: 'rgb(235 224 224 / 11%)',
        color: '#fff',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: '#164846',
      },
    },
    MuiLinearProgress: {
      colorPrimary: {
        borderRadius: '5px',
        height: '7px',
      },
      barColorPrimary: {
        backgroundColor: '#de337a',
      },
    },

    MuiButton: {
      outlinedPrimary: {
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        border: '1px solid #998437 ',
        boxSizing: 'border-box',
        fontSize: '16px',
        minWidth: '120px',
        '&:hover': {
          background:
            'linear-gradient(272.26deg, #AD8208 36.78%, #AD8208 86.13%)',
          color: '#fff',
          border: '2px solid #998437 ',
        },
      },
      outlinedSizelarge: {
        padding: '7px 21px',
        fontSize: '0.9375rem',
        width: '138px',
      },
      containedSizeLarge: {
        padding: '10px 22px',
        '@media(max-width:767px)': {
          height: '35px',
          fontSize: '14px',
          minWidth: 'auto',
          minWidth: '136px',
          height: '46px',
        },
      },
      containedPrimary: {
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        border: '3px solid #F0BD31 ',
        boxSizing: 'border-box',
        marginRight: '20px',
        background:
          'linear-gradient(272.26deg, #EFBD31 36.78%, #DAA50F 86.13%)',
        fontSize: '16px',
        minWidth: '160px',
        boxShadow: 'inset 0px 0px 2px rgba(0, 0, 0, 0.4)',
        '@media(max-width:767px)': {},
        '&:hover': {
          background:
            'linear-gradient(272.26deg, #AD8208 36.78%,#AD8208 86.13%)',
          color: '#fff',
          border: '3px solid #F0BD31 ',
        },
      },
      containedSecondary: {
        borderRadius: '10px',
        color: '#fff',
        fontWeight: 600,
        border: '3px solid #F0BD31 ',
        boxSizing: 'border-box',
        fontSize: '20px',
        minWidth: '160px',
        background:
          'linear-gradient(272.26deg, #EFBD31 36.78%, #DAA50F 86.13%)',
        boxShadow: 'inset 0px 0px 2px rgba(0, 0, 0, 0.4)',
        '@media(max-width:767px)': {},
        '&:hover': {
          background:
            'linear-gradient(272.26deg, #AD8208 36.78%, #AD8208 86.13%)',
          color: '#fff',
          border: '3px solid #F0BD31 ',
        },
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: '0px',
      },
      gutters: {
        //padding: '10px 0 !important',
      },
    },
    MuiDrawer: {
      paperAnchorDockedLeft: {
        borderRight: '0',
      },
    },
    MuiMenu: {
      paper: { top: '47px' },
    },

    MuiTypography: {
      subtitle1: {
        color: '#000',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: ' 16px',
        colorSecondary: {
          color: '#8d8989',
        },
      },
    },
  },
}

const themesOptions = {
  typography: {
    fontWeight: 400,
    fontFamily: "'Roboto', sans-serif",
  },
  palette: {
    type: 'light',
    action: {
      primary: '#20509e',
    },
    background: {
      default: '#FBFBFD',
      dark: '#f3f7f9',
      paper: colors.common.white,
    },
    primary: {
      main: '#898989',
      dark: '#de0d0d',
      light: '#de0d0d',
    },
    secondary: {
      main: '#fff',
    },
    warning: {
      main: '#ffae33',
      dark: '#ffae33',
      light: '#fff1dc',
    },
    success: {
      main: '#54e18c',
      dark: '#54e18c',
      light: '#e2faec',
    },
    error: {
      main: '#ff7d68',
      dark: '#ff7d68',
      light: '#ffe9e6',
    },
    text: {
      primary: '#52565c',
      secondary: '#999999',
    },
    common: {
      black: '#222222',
    },
  },
}

export const createTheme = (config = {}) => {
  let theme = createMuiTheme(_.merge({}, baseOptions, themesOptions))

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme)
  }

  return theme
}
