export default {
  h1: {
    fontWeight: "normal",
    fontSize: 70,

    fontFamily: "'Press Start 2P', cursive",

    lineHeight: "62px",
  },
  h2: {
    fontFamily: "'Press Start 2P', cursive",
    fontWeight: "bold",
    fontSize: 50,
    lineHeight: " 63px",
  },
  h3: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily: "'Press Start 2P', cursive",
  },
  h4: {
    fontWeight: 500,
    fontSize: 24,
    fontFamily: "'Press Start 2P', cursive",
  },
  h5: {
    fontWeight: 500,
    fontSize: 20,
    fontFamily: "'Press Start 2P', cursive",
  },
  h6: {
    // fontWeight: 500,
    lineHeight: "30px",
    fontSize: 18,
    // fontFamily: "'Roboto', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
    // fontFamily: "'Press Start 2P', cursive",
    fontFamily: "'Montserrat', sans-serif",
    //fontSize: '20px',
  },
  body1: {
    fontSize: 12,
    lineHeight: "22px",
    // fontFamily: "'Roboto', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
  },
  body2: {
    fontSize: 14,
    lineHeight: "28px",
    // fontFamily: "'Roboto', sans-serif",
    fontFamily: "'Montserrat', sans-serif",
  },
};
