import React from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: "80px 0px 80px",
    backgroundImage: "url(images/topbg.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(4),
      "& p": {
        marginBottom: "0px",
        marginTop: "1px",
        fontWeight: "400",
        fontSize: "14px",
        lineHeight: "20px",
        color: "#ffffff",
      },
    },
  },
  imgbox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
      maxWidth: "400px",
      margin: "0 auto",
    },
  },
  footercontent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
    "& p": {
      color: "#fff",
      textAlign: "center",
    },
  },
  link: {
    textDecoration: "none",
    color: "#ffffff",
    padding: "0px 10px",
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <Box className={classes.imgbox}>
            <img alt="Foooter Image" src="images/logo.png" width="100%" />
          </Box>
        </Container>
        <Container maxWidth="md">
          <Box className={classes.footercontent}>
            <Typography variant="body1">
              <Link to="/privacy" className={classes.link}>
                Privacy policy
              </Link>
              <Link to="/about-us" className={classes.link}>
                {" "}
                About the Old School RoonScape
              </Link>
              <Link to="/disclaimer" className={classes.link}>
                Disclaimers
              </Link>
              <Link to="/terms" className={classes.link}>
                Terms of Use
              </Link>
              <Link to="/contact" className={classes.link}>
                Contact
              </Link>
              <Link to="/profile" className={classes.link}>
                Profile
              </Link>
            </Typography>
          </Box>
        </Container>
      </Box>
    </>
  );
}
