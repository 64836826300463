import {
  Button,
  Dialog,
  DialogContent,
  Box,
  Grid,
  Typography,
} from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import React, { useContext, useEffect } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";
import { UserContext } from "src/context/User";

export default function ConnectWallet({ open, handleClose }) {
  const user = useContext(UserContext);
  const { account } = useWeb3React();

  useEffect(() => {
    if (account) {
      handleClose();
    }
  }, [account]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box textAlign='center' mt={2} mb={2}>
        <Typography variant='h3' style={{ color: "#fff" }}>
          Connect wallet
        </Typography>
      </Box>
      <DialogContent>
        <Box>
          <Grid container>
            {SUPPORTED_WALLETS.map((item, i) => {
              return (
                <Grid xm={12} sm={12} key={i}>
                  <Box mt={2}>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        window.sessionStorage.removeItem("walletName");
                        window.sessionStorage.setItem("walletName", item.name);
                        user.connectWallet(item.data);
                      }}
                    >
                      <Box textAlign='center'>
                        <img
                          src={item.data.iconName}
                          alt={""}
                          width='25'
                          height='25'
                          style={{ marginRight: 10 }}
                        />
                      </Box>
                      {item.data.name}
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </DialogContent>
      <Box textAlign='center' mt={2} mb={2}>
        <Button onClick={handleClose} variant='contained' color='primary'>
          Close
        </Button>
      </Box>
    </Dialog>
  );
}
