import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/order-history",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Order/Index")),
  },
  {
    exact: true,
    path: "/order-detail",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Order/OrderDetail")),
  },
  {
    exact: true,
    path: "/track",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Order/Track")),
  },
  {
    exact: true,
    path: "/checkout",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Order/Checkout")),
  },
  {
    exact: true,
    path: '/stake',
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Stake/Index")),
  },
  {
    exact: true,
    path: "/players",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/GameTypes")),
  },
  // {
  //   exact: true,
  //   path: "/game-world",
  //   layout: HomeLayout,
  //   component: lazy(() => import("src/views/pages/Home/GameWorld")),
  // },
  {
    exact: true,
    path: "/game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Index")),
  },
  {
    exact: true,
    path: "/main-game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Main")),
  },
  {
    exact: true,
    path: "/how",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/How")),
  },
  {
    exact: true,
    path: "/stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NFTCoin/Index")),
  },

  {
    exact: true,
    path: "/game-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/GameDetails")),
  },
  {
    exact: true,
    path: "/mini-game",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Game/Mini")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/Index")),
  },

  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Terms")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  {
    exact: true,
    path: "/disclaimer",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Disclaimers")),
  },
  {
    exact: true,
    path: "/contact",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Contact")),
  },
  {
    exact: true,
    path: "/howitworks",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/HowitWorks/How")),
  },
  {
    exact: true,
    path: "/roadmap",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/RoadMap")),
  },
  {
    exact: true,
    path: "/teams",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Teams")),
  },
  {
    exact: true,
    path: "/product-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ProductDetails/index")),
  },
  {
    exact: true,
    path: "/grand-exchange",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/GrandExchange/index")),
  },
  {
    exact: true,
    path: "/marketplace",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Marketplace/Index")),
  },
  {
    exact: true,
    path: "/profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/Profile")),
  },

  {
    exact: true,
    path: "/nft-stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/NFTCoin/Index")),
  },
  {
    exact: true,
    path: "/market-details",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/MarketDetails/index")),
  },
  {
    exact: true,
    path: "/coin-stake",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/CoinStake/Index")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/About")),
  },
  {
    exact: true,
    path: "/terms",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Terms")),
  },
  {
    exact: true,
    path: "/team",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Team")),
  },
  {
    exact: true,
    path: "/privacy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Privacy")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Profile/EditProfile")),
  },
  {
    exact: true,
    path: "/resell-nft",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/ResellNFT/Index")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
