import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Avatar,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ConnectWallet from "src/component/ConnectWalletPopUp";
import { useWeb3React } from "@web3-react/core";
import { UserContext } from "src/context/User";

const headersData = [
  {
    label: "About",
    href: "/about-us",
  },
  {
    label: "Mint",
    href: "/mint",
  },
  // {
  //   label: "Roadmap",
  //   href: "/create",
  // },
  {
    label: "Grand Exchange",
    href: "/grand-exchange",
  },
  {
    label: "How it work",
    href: "/howitworks",
  },

  {
    label: "Stake",
    href: "/nft-stake",
  },

  // {
  //   label: "How it works",
  //   href: "/faq",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "600",
    borderRadius: 0,
    minWidth: "auto",
    color: "#ffffff",
    height: "31px",
    padding: "0px 9px",
    letterSpacing: "1px",
    margin: "0 7px",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#fff",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#AD8208",
    },
    "&:hover": {
      color: "#AD8208",
    },
    "&:last-child": {
      backgroundColor: "#1ed760",
    },
  },
  menuButton1: {
    width: "100%",
  },
  login: {
    backgroundColor: "#AD8208",
    marginLeft: "10px",
  },
  loginButton: {
    height: "28px",

    width: "28px",
  },
  toolbar: {
    display: "flex",
    padding: "10px 0",
    justifyContent: "space-between",
    height: "100%",
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "0px",
    right: "-10px",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",
    // height: '44.5px',
    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "normal",
    letterSpacing: "-0.6px",
    lineHeight: "1.75",
    color: "#fff",
    borderBottom: "1px solid #3e3e3e",
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },
  containerHeight: {
    height: "100%",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },
  search: {
    height: "31px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#E6E6E6",
    border: "1px solid #fff",
    "&:hover": {
      backgroundColor: "#ececec",
      border: "1px solid #300760",
    },
    marginLeft: 20,
    width: "140px",
    maxWidth: "257px",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "180px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: theme.spacing(0, 2),
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
  },
  wallet: {
    fontSize: "14px",
    fontWeight: "400",
    fontStyle: "normal",
    lineHeight: "21px",
    color: "#fff",
    border: "1px solid #AD8208",
    padding: "0 15px",
    background: "#AD8208",
    borderRadius: "50px",
    height: "31px",
    margin: "0 7px",
    "&:hover": {
      background: "#fff",
      color: "#AD8208",
    },
    "@media (max-width: 900px)": {
      marginLeft: "6px",
      marginTop: "12px",
    },
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    fontSize: "13px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100px",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  submenu: {
    borderTop: "3px solid #300760",
    top: "25px !important",
  },
  menubox: {
    "& ul": {
      borderRadius: 0,
      background: "rgb(7,49,48)",
      width: "120px",
      padding: "10px",

      "& a": {
        fontSize: "15px",
      },
    },
  },
}));

export default function Header() {
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openWalletConnect, setOpenWalletConnect] = useState(false);
  const { account } = useWeb3React();

  const handleClick3 = (event) => {
    setAnchorEl3(event.currentTarget);
  };
  //const handleClick3 = (event) => {
  //  setAnchorEl3(event.currentTarget)
  //}
  const handleClose3 = () => {
    setAnchorEl3(null);
  };

  const [anchorEl3, setAnchorEl3] = React.useState(null);
  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  //const handleClose1 = () => {
  //  setAnchorEl(null)
  //}
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const {
    loginButton,
    menuMobile,
    menuButton,
    menuButton1,
    menubox,
    toolbar,
    drawerContainer,
    drawericon,
    login,
    logoDrawer,
    community,
    containerHeight,
    search,
    searchIcon,
    mainHeader,
    inputInput,
    inputRoot,
    wallet,
    submenu,
  } = useStyles();
  const history = useHistory();

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  const handleToggle = (name) => {
    setOpen1({ ...open1, [name]: !open1[name] });
  };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open1);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open1 === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open1;
  // }, [open1]);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {productLogo}
        <Grid
          item
          direction='row'
          justify='flex-end'
          alignItems='center'
          style={{ paddingLeft: "0px" }}
        >
          {/* <div className={search}>
              <div className={searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search items, collections, creators"
                classes={{
                  root: inputRoot,
                  input: inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div> */}
          {getMenuButtons()}
          {account ? (
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => user.disConnectWallet()}
            >
              Disconnect
            </Button>
          ) : (
            <Button
              variant='contained'
              color='primary'
              size='large'
              onClick={() => setOpenWalletConnect(true)}
            >
              Connect
            </Button>
          )}

          {/*<Box pl={1}>
              <IconButton
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick3}
               
                size="small"
               
                style={{
                  background: 'rgb(5 164 202)',
                }}
                
                aria-haspopup="true"
             
              >
                {' '}
                <Avatar src={'images/user1.png'} />
              </IconButton>
             
              <Menu
                id="simple-menu"
                style={{ position: 'absolute', top: '3.5%' }}
                anchorEl={anchorEl3}
                keepMounted
                className={menubox}
                open={Boolean(anchorEl3)}
                onClose={handleClose3}
              >
                <Link
                  to="/profile"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  <MenuItem onClick={handleClose3}>Profile</MenuItem>
                </Link>
                <Link
                  to="/edit-profile"
                  style={{ textDecoration: 'none', color: '#fff' }}
                >
                  <MenuItem onClick={handleClose3}>Edit Profile</MenuItem>
                </Link>
              </Menu>
            </Box>*/}
          <Popper
            open={open1.user}
            anchorEl={anchorRef.user.current}
            role={undefined}
            transition
            disablePortal
            className={submenu}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener
                    onClickAway={(e) => handleClose2(e, "user")}
                  >
                    <MenuList
                      autoFocusItem={open1}
                      id='menu-list-grow'
                      onKeyDown={(e) => handleListKeyDown(e, "user")}
                    >
                      <MenuItem component={Link} to='/profile'>
                        My Profile
                      </MenuItem>
                      <MenuItem component={Link} to='/edit-profile'>
                        Edit Profile
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src='images/logo.png' alt='' />
            <IconButton aria-label='delete' size='small' className={login}>
              {" "}
              <Avatar className={loginButton} src={"images/user1.png"} />
            </IconButton>
            {getDrawerChoices()}

            {/* <Button
              className={wallet}
              aria-controls="simple-menu"
              aria-haspopup="true"
              to="/wallet"
              component={Link}
            >
              Connect wallet
            </Button> */}
          </div>
        </Drawer>

        <div>{productLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width='60px'
                height='60px'
                style={{ color: "#EFBD31", fontSize: "30px" }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const productLogo = (
    <Box>
      <Link to='/'>
        <Box display='flex' alignItems='center'>
          <Box>
            <Logo className='logoImg' />
          </Box>
          {/*<Box pl={1}>
            <Typography
              variant="h1"
              style={{
                color: '#fff',
                fontSize: '20px',
                letterSpacing: '10px',
                fontWeight: '500',
              }}
            >
              TIAR
            </Typography>
          </Box>*/}
        </Box>
      </Link>
    </Box>
  );
  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      );
    });
  };

  return (
    <>
      {openWalletConnect && (
        <ConnectWallet
          open={openWalletConnect}
          handleClose={() => setOpenWalletConnect(false)}
        />
      )}
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "relative"}
        // className={history.location.pathname !== "/" ? "InnerHeader" : "Header"}
        elevation={0}
        style={{
          border: "none",
          backgroundImage: "url(images/topbg.png)",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <Container
          maxWidth={history.location.pathname !== "/" ? "lg" : "lg"}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to='/profile'>My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to='/user'>My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to='/resell-nft'>Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to='/create-nft'>Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to='/notification'>Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to='/search'>Search</Link>
        </MenuItem>
      </Menu>
    </>
  );
}
